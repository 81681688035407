@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/layout";

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: variables.$color-canvas;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin: 0;
  }
}

.as-link,
a {
  color: variables.$color-primary-enabled;
  transition: color 300ms ease;
  text-decoration: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: inherit;

  &:visited {
    color: variables.$color-primary-enabled;
  }

  &:active,
  &:hover,
  &:focus {
    color: variables.$color-primary-hover;
  }
}

label {
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 8px;
}

.transparent-button {
  border: 0;
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: auto;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &.as-link {
    display: inline;
  }
}
