.message-editor-menu {
  display: flex;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;

    svg {
      margin: 0;
    }
  }
}
