@use "~@lysaab/ui-2/components/styling/variables";

.path-tag {
  background-color: rgba(variables.$color-blue, 0.1);
  font-weight: 500;
  color: variables.$color-blue;
  transition: background-color 0.25s, color 0.25s;
  overflow: hidden;

  svg {
    path:last-child {
      fill: variables.$color-blue;
      transition: fill 0.25s;
    }
  }

  .path-tag-remove-button {
    transition: background-color 0.25s, color 0.25s;
    cursor: pointer;

    &:hover {
      background-color: variables.$color-blue;
      color: variables.$color-white;

      path:last-child {
        fill: variables.$color-white;
      }
    }
  }

  .path-tag-country-select {
    cursor: pointer;
  }
}
