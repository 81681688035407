@use "~@lysaab/ui-2/components/styling/variables";

.message-list-item {
  > * {
    padding: 0 !important;
  }

  &-header {
    cursor: pointer;
    transition: background-color 0.25s;

    font-weight: 500;

    &:hover {
      background-color: rgba(variables.$color-lightgray, 0.75);
    }
  }
}

.lysa-ui-status-label {
  transition: background 0.15s;
}
