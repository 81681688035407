@use "~@lysaab/ui-2/components/styling/variables";

.autocomplete-input {
  position: relative;
  background-color: transparent;

  &-list {
    z-index: 999;
    position: absolute;
    width: 100%;
    top: 100%;
    overflow-y: auto;
    background-color: variables.$color-white;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-height: 350px;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }

    button {
      outline: 0;
      border: 0;
      background-color: transparent;
      text-align: left;
      padding: 0.6em 1em;
      width: 100%;
      font-size: 16px;
      font-weight: 500;

      &:hover,
      &:focus {
        background-color: rgba(variables.$color-lightgray, 1);
      }
    }
  }
}
