.message-list-item-header {
  .system-message-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    text-align: left;

    *:last-child {
      margin-bottom: 0;
    }
  }

  .lysa-ui-snackbar {
    margin-bottom: 0;
    justify-content: flex-start;

    & + .lysa-ui-snackbar {
      margin-top: 12px;
    }
  }
}
