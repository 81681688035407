@use "~@lysaab/ui-2/components/styling/variables";

.message-editor {
  min-height: 200px;
  height: 200px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid variables.$color-blue;
  outline-color: variables.$color-blue;
  resize: vertical;
  overflow: auto;

  .ProseMirror {
    min-height: 100%;
    height: 100%;
    outline: 0;
  }
}
